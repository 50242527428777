@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Poppins', sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}